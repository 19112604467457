import React from "react";
import profilePic from "./mattchan-profile.png";
import "./style.css";

function ProfileCard() {
  return (
    <div className="profileCard">
      <img className="profilePic" src={profilePic} alt="profile picture" />
      <p>
        Hi! I'm Matt, and a Full Stack Developer by trade, working on everything
        from mocking up wireframes to coding front-end interactions to
        developing the backend stack. I enjoy taking on creative projects, and
        am always looking to try new things. So if you have an interesting
        project, contact me on gmail with my username 'mattam'.
      </p>
    </div>
  );
}

export default ProfileCard;
