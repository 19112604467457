import React from "react";

import ProfileCard from "./components/profileCard";
import SkillsAndExperience from "./components/skillsAndExperience";
import "./App.css";

function App() {
  return (
    <div className="App">
      <ProfileCard />
      <SkillsAndExperience />
    </div>
  );
}

export default App;
