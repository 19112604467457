import React from "react";
import "./style.css";

function SkillsAndExperience() {
  return (
    <div className="skillsAndExperience">
      <h3>Skills</h3>
      <ul className="skills">
        <li>
          <h4>Design</h4>: Figma, Sketch, Photoshop, Illustrator, Flinto, Maya,
          3D Modeling / Animation
        </li>
        <li>
          <h4>Frontend</h4>: Javascript, React.js, React Native, Typescript,
          Mobx, Redux, HTML, CSS
        </li>
        <li>
          <h4>Backend</h4>: Node.js, PHP, Express, mongoDB, GoLang, mySQL, AWS
        </li>
      </ul>

      <h3>Experience</h3>
      <ul className="experience">
        <li>
          <h4>2016-Present:</h4>
          <a href="http://www.basiltech.org">Basil Tech</a>
          <span> / </span> Non-profit Co-founder
        </li>
        <li>
          <h4>2018-2020:</h4> <a href="http://www.curology.com/">Curology</a>
          <span> / </span>
          Software Engineer
        </li>
        <li>
          <h4>2016-2018:</h4>
          <a href="http://www.readscripture.org/">Read Scripture</a>
          <span> / </span> Software Engineer
        </li>
        <li>
          <h4> 2013-2016:</h4> <a href="https://abide.is">Carpenters Code</a>
          <span> / </span>
          Software Engineer
        </li>
        <li>
          <h4>2011-2013:</h4>{" "}
          <a className="closed" href="#">
            Symantec
          </a>
          <span> / </span>
          Web Developer
        </li>
        <li>
          <h4>2010-2013:</h4>{" "}
          <a className="closed" href="#">
            Wonderflower Letterpress
          </a>
          <span> / </span> Co-founder
        </li>
        <li>
          <h4>2004-2009:</h4>{" "}
          <a className="closed" href="#">
            2Wire
          </a>
          <span> / </span> UI Developer
        </li>
        <li>
          <h4>2002-2004:</h4>{" "}
          <a className="closed" href="#">
            Caligari
          </a>
          <span> / </span> 3D tooling and teaching
        </li>
      </ul>
    </div>
  );
}

export default SkillsAndExperience;
